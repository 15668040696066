.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

#box {
  -webkit-transition: background-color 2s;
  /* For Safari 3.1 to 6.0 */
  transition: background-color 2s;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #000 !important;
}

@media print {
  .printOnly {
    display: block;
  }

  .hideOnPrint {
    display: none;
  }
}

@media screen {
  .printOnly {
    display: none;
  }

  .hideOnPrint {
    display: block;
  }
}

@page {
  size: auto;
  margin: 200mm;
}

.itemd {
  color: #108dcd !important;
  text-decoration: none;
}

.itemd:link {
  position: "relative";
  display: "block";
  text-decoration: "none";
}

.itemd:hover {
  text-decoration: none;
}

.itemd:active {
  color: #fff !important;
}

.item {
  text-decoration: none;
}

.item:link {
  position: "relative";
  display: "block";
  text-decoration: "none";
}

.item:hover {
  text-decoration: none;
}

.item:active {
  color: #fff !important;
}

.App-link {
  color: #61dafb;
}

.MuiGrid-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #87cefa;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.MuiGridList-root {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

.MuiGridList-root::-webkit-scrollbar {
  width: 5px !important;
}

.MuiGridList-root {
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.MuiGridList-root::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.MuiGridList-root::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.bestSelling {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

.bestSelling::-webkit-scrollbar {
  width: 5px !important;
  height: 10px !important;
}

.bestSelling {
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.bestSelling::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.bestSelling::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.ag-root {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

.ag-root::-webkit-scrollbar {
  width: 5px !important;
}

.ag-root {
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.ag-root::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.ag-root::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.MuiTableContainer-root {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.MuiTableContainer-root {
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.search .results {
  overflow-y: auto;
  max-height: 300px;
}

.style_img {
  background-color: blueviolet;
}


.login_learnmore {
  color: rgb(63,81,181);
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  border-bottom:1px solid rgb(63,81,181);
}
.login_learnmore:hover {
  color: rgb(24, 42, 145);
  border-bottom:1px solid rgb(24, 42, 145);
}